import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";
import { forkJoin, map, Observable, of, shareReplay, take, takeUntil } from "rxjs";
import { IExamPeriod } from "../../models/exam/exam-period.model";
import dayjs from "dayjs";

@Injectable()
export class ExamPeriodService {
  examMonthDiff?: number;
  examYear?: number;
  periodId?: number;

  nextExamMonthDiff?: number;
  nextExamYear?: number;
  nextPeriodId?: number;
  private cachedData$: Observable<{ examMonthDiff: number; examYear: number, periodId: number }> | null = null;
  private cachedNextPeriodData$: Observable<{ examMonthDiff: number; examYear: number, periodId: number }> | null = null;

  constructor(
    private api: ApiService
  ) {
  }

  getPeriods() {
    return this.api.get(`/public/exam-periods/active`)
  }

  getNextExamPeriods() {
    return this.api.get(`/public/exam-periods/next`);
  }

  getSelectedPeriodDiff(periodId: number){
    return forkJoin({
      nextPeriod: this.getNextPeriodsDiff().pipe(take(1)),
      activePeriod: this.getActivePeriodsDiff().pipe(take(1))
    }).pipe(
      map(({nextPeriod, activePeriod}) => {
        if (nextPeriod.periodId === periodId) {
          return nextPeriod;
        } else if (activePeriod.periodId === periodId) {
          return activePeriod;
        } else {
          return null; // If no matching periodId is found, return null
        }
      })
    );
  }

  getNextPeriodsDiff() {
    // If data is already cached, return the stored values
    if (this.nextExamMonthDiff !== undefined && this.nextExamYear !== undefined) {
      return of({examMonthDiff: this.nextExamMonthDiff, examYear: this.nextExamYear, periodId: this.nextPeriodId});
    }

    // If the HTTP request is already in progress, return the same observable
    if (this.cachedNextPeriodData$) {
      return this.cachedNextPeriodData$;
    }

    // Make an HTTP request and cache the result
    this.cachedNextPeriodData$ = this.getNextExamPeriods().pipe(
      take(1),
      map((r) => {
        const periods = r.data as IExamPeriod[];
        if (!periods.length) {
          return {examMonthDiff: 0, examYear: 0, periodId: 0}; // Handle empty response case
        }

        const minExamDate = periods.reduce((min, current) =>
          dayjs(current.endAt).isBefore(dayjs(min.endAt)) ? current : min
        ).endAt;

        const minDate = dayjs(minExamDate);
        const now = dayjs();
        let examMonthDiff = minDate.diff(now, "month");

        // Adjust for days difference
        if (minDate.date() - now.date() > 0) {
          examMonthDiff += 1;
        } else if ((minDate.diff(now, 'day') / 30) > examMonthDiff) {
          examMonthDiff += 1;
        }

        // Cache the results
        this.nextExamMonthDiff = examMonthDiff;
        this.nextExamYear = minDate.year();

        return {examMonthDiff, examYear: this.nextExamYear, periodId: periods[0].periodId};
      }),
      shareReplay(1) // Ensures multiple subscribers share the same request result
    );

    return this.cachedNextPeriodData$;
  }

  getActivePeriodsDiff() {
    // If data is already cached, return the stored values
    if (this.examMonthDiff !== undefined && this.examYear !== undefined) {
      return of({examMonthDiff: this.examMonthDiff, examYear: this.examYear, periodId: this.periodId});
    }

    // If the HTTP request is already in progress, return the same observable
    if (this.cachedData$) {
      return this.cachedData$;
    }

    // Make an HTTP request and cache the result
    this.cachedData$ = this.getPeriods().pipe(
      take(1),
      map((r) => {
        const periods = r.data as IExamPeriod[];
        if (!periods.length) {
          return {examMonthDiff: 0, examYear: 0, periodId: 0}; // Handle empty response case
        }

        const minExamDate = periods.reduce((min, current) =>
          dayjs(current.endAt).isBefore(dayjs(min.endAt)) ? current : min
        ).endAt;

        const minDate = dayjs(minExamDate);
        const now = dayjs();
        let examMonthDiff = minDate.diff(now, "month");

        // Adjust for days difference
        if (minDate.date() - now.date() > 0) {
          examMonthDiff += 1;
        } else if ((minDate.diff(now, 'day') / 30) > examMonthDiff) {
          examMonthDiff += 1;
        }

        // Cache the results
        this.examMonthDiff = examMonthDiff;
        this.examYear = minDate.year();

        return {examMonthDiff, examYear: this.examYear, periodId: periods[0].periodId};
      }),
      shareReplay(1) // Ensures multiple subscribers share the same request result
    );

    return this.cachedData$;
  }
}
